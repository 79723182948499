import React from "react";
import styled from "@emotion/styled";
import {
  Container,
  Grid,
  Typography,
  Link,
  IconButton,
  Button,
  TextField,
  useMediaQuery,
  Box,
} from "@mui/material";
import { Facebook, Twitter, LinkedIn, GitHub } from "@mui/icons-material";

const SocialIconsContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "8px",
  alignItems: "center",
  margin: "1em 1em",
});

const SubscribeContainer = styled(Box)({
  marginTop: 16,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
  textAlign: "center",
});

const SubscribeInput = styled(TextField)({
  marginRight: 16,
  width: "100%",
});

const SectionContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  textAlign: "center",
  gap: "3rem",
  "& ul": {
    listStyleType: "none",
    paddingLeft: 0,
    textDecoration: "none",
  },
  "& li": {
    marginBottom: "0.5rem",
    "& a": {
      textDecoration: "underline",
    },
  },
});

function Footer() {
  const isSmartphone = useMediaQuery("(max-width:500px)");
  const isTablette = useMediaQuery("(min-width:501px) and (max-width:915px)");
  const isDesktop = useMediaQuery("(min-width:915px) and (max-width:1280px)");
  return (
    <Container sx={{marginTop:'4em'}}>
      <Grid
        item
        xs={12}
        sm={6}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          marginTop: "2rem",
          backgroundColor: "ButtonFace",
        }}
      >
        <SocialIconsContainer>
          <Box sx={{display: "flex", justifyContent: "space-around"}}>
            
            <IconButton href='' color='inherit'>
            <Facebook sx={{ fontSize: "2rem" }} />
          </IconButton>
            
            <IconButton href='' color='inherit'>
            <Twitter sx={{ fontSize: "2rem" }} />
          </IconButton>
            
            <IconButton href='' color='inherit'>
            <LinkedIn sx={{ fontSize: "2rem" }} />
          </IconButton>
            <IconButton href='' color='inherit'>
            <GitHub sx={{ fontSize: "2rem" }} />
          </IconButton>

          </Box>
          <Box>
            <Typography>
              Suivez-nous sur les réseaux sociaux pour rester à jour avec nos
              dernières nouvelles.
            </Typography>
          </Box>
        </SocialIconsContainer>
      </Grid>
      <hr />
      <Container
        sx={{
          display: "flex",
          flexDirection: isSmartphone || isTablette ? "column" : "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Grid item xs={12} sm={6}>
          <SectionContainer>
            <Typography variant="h3">
                MOMAIIFOUNDING
            </Typography>
            <Box>
              <Typography variant="h3" sx={{ fontWeight: "bold" }}>
                {" "}
                À propos de nous
              </Typography>

              <Typography>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. <br />
                Sed dapibus justo nec urna feugiat, eget iaculis justo
                facilisis.
              </Typography>
            </Box>
          </SectionContainer>
        </Grid>

        <Grid item xs={12} sm={4}>
          <SectionContainer>
            <Box>
              <Typography variant="h3" sx={{ fontWeight: "bold" }}>
                {" "}
                Information utiles
              </Typography>
              <ul>
                <li>
                  <Link href="/faq">FAQ</Link>
                </li>
                <li>
                  <Link href="/contact">Nous contacter</Link>
                </li>
                <li>
                  <Link href="/termes">Termes et conditions</Link>
                </li>
              </ul>
            </Box>
          </SectionContainer>
        </Grid>

        <Grid item xs={12} sm={4}>
          <SectionContainer>
            <Box>
              <Typography variant="h3" sx={{ fontWeight: "bold" }}>
                {" "}
                Information utiles
              </Typography>
              <ul>
                <li>
                  <Link href="/faq">FAQ</Link>
                </li>
                <li>
                  <Link href="/contact">Nous contacter</Link>
                </li>
                <li>
                  <Link href="/termes">Termes et conditions</Link>
                </li>
                <li>
                  <Link href="/Politique de confidentialité">
                    Politique de confidentialité
                  </Link>
                </li>
              </ul>
            </Box>
          </SectionContainer>
        </Grid>
      </Container>

      <SubscribeContainer>
        <Grid
          container
          spacing={2}
          alignItems="center"
          marginBottom="4em"
          marginTop="2.5em"
        >
          <Grid item xs={12} sm={6}>
            <Box sx={{ backgroundColor: "ThreeDFace", padding: "1em" }}>
              <Typography variant="h5">Souscribe to our</Typography>
              <Typography variant="h3" sx={{ fontWeight: "semi-bold" }}>
                NEWSLETTER
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "1em",
              }}
            >
              <SubscribeInput variant="outlined" label="Adresse e-mail" />
              <Button variant="contained" color="secondary">
                S'abonner
              </Button>
            </Box>
          </Grid>
        </Grid>
      </SubscribeContainer>

      <Typography
        variant="h5"
        align="center"
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: "1em",
        }}
      >
        © {new Date().getFullYear()} MOMAIIFUNDING. Tous droits réservés.
      </Typography>
    </Container>
  );
}
export default Footer;
