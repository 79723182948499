import React from 'react';
import Box from '@mui/material/Box';
// import LinearProgress from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';


const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));

const ProgressBar = ({ value }) => {
  return (
    // <LinearProgress
    //   variant="determinate"
    //   value={value} // La valeur de la barre de progression (de 0 à 100)
    //   sx={{ width: '100%', height: '15px'}} // Vous pouvez personnaliser la largeur ici
    // />

    <Box sx={{ flexGrow: 1 }}>
      <BorderLinearProgress variant="determinate" value={value} />
    </Box>
  );
};

export default ProgressBar;
