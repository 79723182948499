import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
// import { AppProvider } from "./utils/appContext";
import { AuthProvider, useAuth } from "./utils/AuthContext";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";
import App from "./App";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <BrowserRouter>
    <ToastContainer
      style={{ marginTop: "35rem" }}
      position="top-center"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss={false}
      draggable
      pauseOnHover
    />
    <AuthProvider>
      <App />
    </AuthProvider>
  </BrowserRouter>
);
