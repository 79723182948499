import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";

import Header from "../../components/Header";
import ImmDropZone from "../../components/ImmDropZone";
import FormUploadFile from "../../components/FormUploadFile";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";


const SupportingDocumentation = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box m="20px">
      <Header title="DOCUMENTS JUSTIFICATIFS" subtitle="Utilisez le formulaire pour télécharger les documents demandé" />

      <Grid2
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <FormUploadFile />
      </Grid2>


    </Box>
  );
};

export default SupportingDocumentation;
