import React, {useContext, useState, useEffect} from 'react'
import { Box, Button, TextField } from '@mui/material'
import { Formik } from "formik";
import * as yup from 'yup';
import { useMediaQuery } from "@mui/material";

import { useSidebarContext } from '../pages/global/sidebar/sidebarContext';
import MmfApi from '../utils/MmfApi';
import { toast } from 'react-toastify';

import { getValueFromLocalStorage } from '../utils/Utils';


const FormProfile = () => {
    const { user } = useSidebarContext();
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const phoneRegExp = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;



    const updateUser = async (userInfo) => {
        // Retireve user token
        const userToken = getValueFromLocalStorage('_MOMAII_AUTH_LOGGED_IN_TOKEN', null)
        console.log("+++++++ DATA TO SEND +++++++++: ", userInfo)
        const response = await MmfApi.updateUser(userToken, userInfo)
        if (response) {
            toast.success("User updated")
        } else {
            console.log("++++++++++++++++: ", response)
        }
    }


    const handleFormSubmit = (values) => {
        console.log(values);
        // editUser(values);
        updateUser(values)
        console.log(values);
        
    };

    
    const initialValues = {
        firstName: user ? user.firstName : "",
        lastName: user ? user.lastName : "",
        email: user ? user.email : "",
        mobilePhone: user ? user.mobilePhone : "",
        address: user ? user.address : "",
        birthDate: user ? user.birthDate : "",
      };
    
    
    const checkoutSchema = yup.object().shape({
        firstName:yup.string().required("Required"),
        lastName:yup.string().required("Required"),
        email:yup.string().email("Invalid email!").required("Required"),
        mobilePhone:yup.string().matches(phoneRegExp, "phone number is not valid!").required("Required"),
        address:yup.string().required("Required"),
        birthDate:yup.string().required("Required"),

    })

    return (
        <>
            <Box m="20px">
                {/* <Formik onSubmit={handleFormSubmit} initialValues={initialValues} > */}
                <Formik onSubmit={handleFormSubmit} initialValues={initialValues} validationSchema={checkoutSchema}>
                    {({ values, errors, touched, handleBlur, handleChange, handleSubmit,}) => (
                    <form onSubmit={handleSubmit}>
                        <Box
                        display="grid"
                        gap="30px"
                        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                        sx={{
                            "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                        }}
                        >
                        <TextField
                            fullWidth
                            variant="filled"
                            type="text"
                            label="First Name"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.firstName}
                            name="firstName"
                            error={!!touched.firstName && !!errors.firstName}
                            helperText={touched.firstName && errors.firstName}
                            sx={{ gridColumn: "span 2" }}
                        />
                        <TextField
                            fullWidth
                            variant="filled"
                            type="text"
                            label="Last Name"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.lastName}
                            name="lastName"
                            error={!!touched.lastName && !!errors.lastName}
                            helperText={touched.lastName && errors.lastName}
                            sx={{ gridColumn: "span 2" }}
                        />
                        <TextField
                            fullWidth
                            variant="filled"
                            type="text"
                            label="Email"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.email}
                            name="email"
                            error={!!touched.email && !!errors.email}
                            helperText={touched.email && errors.email}
                            sx={{ gridColumn: "span 4" }}
                        />
                        <TextField
                            fullWidth
                            variant="filled"
                            type="text"
                            label="mobile phone"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.mobilePhone}
                            name="mobilePhone"
                            error={!!touched.mobilePhone && !!errors.mobilePhone}
                            helperText={touched.mobilePhone && errors.mobilePhone}
                            sx={{ gridColumn: "span 4" }}
                        />
                        <TextField
                            fullWidth
                            variant="filled"
                            type="text"
                            label="Address"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.address}
                            name="address"
                            error={!!touched.address && !!errors.address}
                            helperText={touched.address && errors.address}
                            sx={{ gridColumn: "span 4" }}
                        />
                        <TextField
                            fullWidth
                            variant="filled"
                            type="text"
                            label="birth date"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.birthDate}
                            name="birthDate"
                            error={!!touched.birthDate && !!errors.birthDate}
                            helperText={touched.birthDate && errors.birthDate}
                            sx={{ gridColumn: "span 4" }}
                        />
                        </Box>
                        <Box display="flex" justifyContent="end" mt="20px">
                        <Button type="submit" color="secondary" variant="contained">
                            Enregistrer
                        </Button>
                        </Box>
                    </form>
                    )}
                </Formik>
            </Box>
        </>
      );
}

export default FormProfile