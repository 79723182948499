import React from 'react';
import { Box,List, Typography, useMediaQuery} from '@mui/material';
import MobileAppbar from './MobileAppbar';
import DesktopAppbar from './DesktopAppbar';
import styled from '@emotion/styled';
import "@fontsource/montez"


const AppbarContainer = styled(Box)(() => ({
    display: 'flex',
    marginTop: 4,
    justifyContent: 'center',
    alignItems: 'center',
    padding: '2px 8px',
}));

// Header stylé
const AppbarHeader = styled(Typography)(() => ({
    padding: '4px',
    flexGrow: 1,
    fontSize: '2em',
    fontFamily: '"Montez", "cursive"',
}));

const Mylist = styled(List)(({type})=>({
  display: type === 'row' ? 'flex' : 'block',
  flexGrow: 3,
  justifyContent: 'center',
  alignItems: 'center'
}))

const ActionIconContainerMobile = styled(Box)(() => ({
  display: 'flex',
  position: 'fixed',
  botom: 0,
  left: 0,
  width:"100%",
  alignItems: 'center',
  zIndex: 99,
  borderTop: '1px solid rgba(0,0,0,0.3)'
}));

const ActionIconContainerDesktop = styled(Box)(() => ({
  flexGrow: 0,
}));



const Appbar = () => {

  const isSmartphone = useMediaQuery('(max-width:500px)');
  const isTablette = useMediaQuery('(min-width:501px) and (max-width:915px)');
  const isDesktop = useMediaQuery('(min-width:915px) and (max-width:1280px)');
  const isExtraLargeScreen = useMediaQuery('(min-width:1281px)');


  return (
    <>
    {isSmartphone ?  <MobileAppbar /> : isTablette? <MobileAppbar /> : <DesktopAppbar  />}
   </>
  )
}

export {AppbarContainer, AppbarHeader, Mylist, ActionIconContainerDesktop, ActionIconContainerMobile}
export default Appbar