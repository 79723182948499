import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  ButtonGroup,
  IconButton,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { tokens } from "../../../theme";
import Header from "../../../components/Header";
import { useAuth } from "../../../utils/AuthContext";

// import ProjectCard from "../../components/ProjectCard";
import ProjectCard from "../../../components/ProjectCard";


const ProjectList = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { user, projects } = useAuth();
  const [loadingSetup, setLoadingSetup] = useState(true);


  useEffect(() => {
    if (projects) {
      setLoadingSetup(false);
    }
  }, [projects]);


  return (
    <Box m="20px">
      {/* HEADER */}
        <Header title="PROJETS" subtitle="All our project" />
        
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        {projects.map((project, index) => {
          return (
            <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
              <ProjectCard key={index} project={project} />
            </Grid>
          )
        })}
        </Grid>
          
    </Box>
  );
};

export default ProjectList;
