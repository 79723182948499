import React from "react";
import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  TextField,
  useTheme,
  Grid,
  Card,
  Container,
  CardContent,
  IconButton,
} from "@mui/material";

import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import DiamondIcon from "@mui/icons-material/Diamond";

import styled from "@emotion/styled";
import Image from "../../assets/img/banner-bg-1.jpg";
import { useNavigate } from "react-router-dom";
import { tokens } from "../../theme";

function ColoredPhrase({ text, color }) {
  return <p style={{ color, margin: "0" }}>{text}</p>;
}

const BarnerContainer = styled(Box)(() => ({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  marginTop: "1em",
  justifyContent: "center",
  alignItems: "center",
  padding: "2px 8px",
}));

const Barner = () => {
  const isSmartphone = useMediaQuery("(max-width:500px)");
  const isTablette = useMediaQuery("(min-width:501px) and (max-width:915px)");
  const isDesktop = useMediaQuery("(min-width:916px)");
  const isMobile = isSmartphone || isTablette;
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const cards = [
    {
      id: 1,
      boldText: "Wahou, investissez à partir de 100000 F CFA",
      text: "Ouvrez votre compte en moins de 5 minutes et investissez, en ligne, sur les projets de votre choix.",
      icon: <DiamondIcon fontSize="large"/>,
    },
    {
      id: 2,
      boldText: "Obtenez une rentabilité à court et moyen terme",
      text: "8,9 % de performance annuelle en 2022 sur un placement de 23 mois en moyenne NB : Les performances passées ne préjugent pas des performances futures.",
      icon: <TrendingUpIcon fontSize="large"/>,
    },
    {
      id: 3,
      boldText: "Diversifiez vos placements",
      text: "Investissez dans des projets immobiliers sélectionnés pour vous au travers d'une expérience simple et transparente, 100 % digitalisée.",
      icon: <AccountBalanceWalletIcon fontSize="large"/>,
    },
  ];

  return (
    <>
      <BarnerContainer
        sx={{
          backgroundImage: `url(${Image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          height: "50vh",
        }}
      >
        <Box
          sx={{
            marginTop: "1em",
            fontSize: isMobile ? "2em" : "4em",
            fontWeight: "bold",
            backgroundColor: "transparent",
            width: isMobile ? "90%" : "80%",
            position: "absolute",
            top: isMobile ? "25%" : isDesktop ? "28%" : "40%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            textAlign: "center",
            color: "white",
            zIndex: 1,
          }}
        >
          <ColoredPhrase text="Investir dans l'immobilier" color="#473FAD" />
          <ColoredPhrase text="Autrement" color="#FF9933" />
        </Box>
        <Box
          sx={{
            marginTop: isMobile ? "3em" : "5em",
            width: isMobile ? "70%" : "400px",
            backgroundColor: "transparent",
            position: "absolute",
            fontSize: isMobile ? "0.8em" : "1em",
            fontWeight: "bold",
            top: isMobile ? "50%" : isDesktop ? "52%" : "55%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            textAlign: "center",
            color: "white",
            zIndex: 1,
          }}
        >
          Créez gratuitement et en quelques minutes votre compte investisseur et
          commencez à investir dès aujourd'hui dans les projets immobiliers
          sélectionnés par nos analystes.
        </Box>
        <Box
          sx={{
            zIndex: 1,
            position: "absolute",
            top: isDesktop
              ? "calc(65% + 2em)"
              : isMobile
              ? "calc(65% + 2em)"
              : "calc(65% + 4em)",
          }}
        >
          <Box
            component="form"
            noValidate
            sx={{
              mt: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: "2em",
            }}
          >
            <TextField
              margin="normal"
              sx={{ backgroundColor: "#FFF" }}
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <Button
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
                margin: "0px 50px",
              }}
              onClick={() => navigate("/sign-up")}
            >
              Inscription
            </Button>
          </Box>
        </Box>
      </BarnerContainer>
      <BarnerContainer>
        <Container sx={{ py: 8 }} maxWidth="lg">
          <Grid container spacing={4}>
            {cards.map((card) => (
              <Grid item key={card.id} xs={12} sm={6} md={4}>
                <Card
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <CardContent sx={{ flexGrow: 1 }}>
                    <IconButton sx={{ marginBottom: "0.5em", }}>
                      {card.icon}
                    </IconButton>
                    <Typography
                      variant="h3"
                      fontWeight="bold"
                      sx={{ marginBottom: "1em" }}
                    >
                      {card.boldText}
                    </Typography>
                    <Typography variant="h5">{card.text}</Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </BarnerContainer>
    </>
  );
};

export default Barner;
