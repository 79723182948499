import { toast } from "react-toastify";
import { MdGpsFixed } from "react-icons/md";
import { FiCheck } from "react-icons/fi";

import "react-toastify/dist/ReactToastify.css";

export const notify = (text = "hello", mode = "success", top = false) => {
  if (mode === "success")
    toast.success(text, {
      className: "toast-position",
      position: top ? "top-right" : "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      icon: FiCheck,
    });
  if (mode === "error")
    toast.error(text, {
      position: top ? "top-center" : "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    });
  if (mode === "gps")
    toast.error(text, {
      position: top ? "top-center" : "bottom-center",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      icon: MdGpsFixed,
    });
};
