import React from "react";
import styled from "@emotion/styled";
import {
  Container,
  Grid,
  Typography,
  Button,
  TextField,
  useMediaQuery,
  Box,
} from "@mui/material";
import { LocationOn, Phone, Email } from "@mui/icons-material";

const ContactPageContainer = styled(Container)({
  marginTop: "4em",
});

const ContactSectionContainer = styled(Grid)({
  display: "flex",
  flexDirection: "column",
  textAlign: "center",
  gap: "3rem",
  "& ul": {
    listStyleType: "none",
    paddingLeft: 0,
    textDecoration: "none",
  },
  "& li": {
    marginBottom: "0.5rem",
    "& a": {
      textDecoration: "underline",
    },
  },
});


const ContactInfo = styled(Typography)({
    display: "flex",
    alignItems: "center",
    gap: "0.5rem",
  });

  const ContactInfoContainer = styled(Box)({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "0.5rem",
  });
  
  


function ContactPage() {
  const isSmartphone = useMediaQuery("(max-width:500px)");
  const isTablette = useMediaQuery("(min-width:501px) and (max-width:915px)");
  return (
    <ContactPageContainer>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <ContactSectionContainer>
            <Typography variant="h3">Nous contacter</Typography>
            <Box>
              <form>
                <Box sx={{ display: "flex", gap: "1em" }}>
                  <TextField
                    label="Nom"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                  />
                  <TextField
                    label="Adresse e-mail"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                  />
                </Box>
                <TextField
                  label="Sujet"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="Message"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={6}
                  margin="normal"
                />
                <Button variant="contained" color="primary">
                  Envoyer
                </Button>
              </form>
            </Box>
          </ContactSectionContainer>
        </Grid>
        <Grid item xs={12} sm={6}>
          <ContactSectionContainer>
            <Typography variant="h3">Informations de contact</Typography>
            <ContactInfoContainer>
              <ContactInfo>
                <LocationOn />
                <Typography>
                  123 Rue de l'Entreprise, Ville, Pays
                </Typography>
              </ContactInfo>
              <ContactInfo>
                <Phone />
                <Typography>+123 456 7890</Typography>
              </ContactInfo>
              <ContactInfo>
                <Email />
                <Typography>contact@entreprise.com</Typography>
              </ContactInfo>
            </ContactInfoContainer>
          </ContactSectionContainer>
        </Grid>
      </Grid>
    </ContactPageContainer>
  );
}

export default ContactPage;
