import styled, { createGlobalStyle } from "styled-components";

import signImg from "./images/Signature.svg";
import WallPaperLeft from "./images/wallpaperLeft.svg";
import WallPaperRight from "./images/wallpaperRight.svg";

/**
 * Les couleurs
 */
//bleu foncé
export const firstColor = "#02378E";

// orange
export const secondColor = "#ff7e63";

//bleu cyan
export const thirdColor = "#2cdb8b";

//bleu tres clair
export const fourthColor = "#81abf9";

// bleu clair background
export const fifthColor = "#F8F9FA";
//export const fifthColor = "#E9EEF8";

/**
 * Les fonts
 */
//export const firstFont = "Quicksand";Poppins
//export const secondFont = "Montserrat";
export const firstFont = "Poppins";
export const secondFont = "Quicksand";

export const smallFontSize = "14px";

/**
 * Custom
 */

const GlobalStyles = createGlobalStyle`

*{
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

html {
    box-sizing: border-box;
   
}
body{
    font-family: ${firstFont}, sans-serif;
    font-weight: 400;
    line-height: 1.6;
    //font-size: 1.6rem;
    background: ${fifthColor};
}
a {
    text-decoration: none;
    color: white;
  }
`;

export const RootDiv = styled.div`
  //background: #f1f1f1;
  min-height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;

  background: ${fifthColor};
  align-items: center;
  @media (max-width: 900px) {
    //min-height: calc(100vh - 243px);
  }
  &::before {
    /*background: url() no-repeat top left;
    content: " ";
    display: block;
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    opacity: 0.3;
    background-position: 50% 0;
    background-size: cover;*/
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1280px;
  width: 100%;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media (max-width: 900px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const Col = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const ColDisapear = styled(Col)`
  padding: 30px;
  @media (max-width: 1140px) {
    display: none;
  }
`;

export const WrapperRadius = styled.div`
  border: 1px solid #c5ced6;
  border-radius: 10px;
  padding: 50px 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const BoxRadius = styled.div`
  max-width: 700px;
  min-width: 500px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left 0px top 20px;
  background-size: 250px;
  border: 1px solid #c5ced6;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  @media (max-width: 750px) {
    background: none !important;
    padding: 20px 30px;
  }
`;

export const BabRadius = styled.div`
  display: flex;
  padding: 20px;
  overflow: hidden;
  flex-direction: column;
  background-color: grey;
  margin-bottom: 20px;

  position: relative;

  background: white;
  border-radius: 1rem;
  border: 1px solid #02378e2b;
`;

export const BabLabel = styled.label`
  display: inline-block;
  white-space: nowrap;
  font-family: ${firstFont};
  font-size: ${smallFontSize};
  font-weight: 600;
  color: #535353;
`;

export const BabInput = styled.input`
  height: 53px;
  width: 100%;
  padding: 8px 16px;
  //border: 1px solid #c5ced6;
  border: ${({ error }) => (error ? "1px solid red" : "1px solid #c5ced6")};
  border-radius: 4px;
  font-size: ${smallFontSize};
  font-weight: 500;
  color: #424770;
  margin-bottom: 10px;
  transition: box-shadow 0.2s;
  outline: none;
  font-family: ${firstFont};

  &:focus {
    border: 2px solid #508ef4;
  }

  &:disabled {
    background-color: #f8f9fa;
    color: #8c9399;
  }
  &::placeholder {
    color: #c2cfd9;
  }
`;

export const BabText = styled.div`
  display: flex;
  align-items: center;
  font-size: ${smallFontSize};
  line-height: 20px;
  font-family: ${firstFont};
  font-weight: 400;
  color: ${firstColor};
`;
export const BabTextMedium = styled(BabText)`
  font-size: 16px;
  font-weight: 600;
`;

export const BabHr = styled.hr`
  border: none;
  border-top: 0px solid #e1e1e1;
  color: ${({ hrColor }) => (hrColor ? hrColor : firstColor)};
  width: 100%;
  font-family: ${secondFont};
  font-size: ${({ hrSize }) => (hrSize ? hrSize : "medium")};
  font-weight: ${({ hrWeight }) => (hrWeight ? hrWeight : "400")};
  overflow: visible;
  height: 0px;
  text-align: ${({ hrAlign }) => (hrAlign ? hrAlign : "center")};

  &:after {
    background: white;
    content: "${({ hrText }) => (hrText ? hrText : "Or")}";
    padding: 15px;
    position: relative;
    top: -13px;
    @media (max-width: 700px) {
      padding: 0px;
      margin-left: 10px;
    }
  }
`;

export const BabBlueText = styled.div`
  font-size: 13px;
  line-height: 1.2;
  font-weight: 700;
  font-family: ${firstFont}, arial, helvetica, sans-serif;
  color: #449ce3;

  &:hover {
    color: ${secondColor};
  }

  &::after {
    content: ")";
    margin-left: 0.8rem;
    font-size: 20px;
    font-weight: 600;
  }
  &::before {
    content: "(";
    margin-right: 0.8rem;
    font-size: 20px;
    font-weight: 600;
  }
`;

export const BabTitle = styled.div`
  font-size: large;
  font-family: ${secondFont}, arial, helvetica, sans-serif;
  font-weight: 500;
  color: ${firstColor};
  line-height: 27px;
  display: flex;
  justify-content: space-between;
  margin: 10px 0px;
  z-index: 2;
`;

export const BabBox = styled.div`
  display: flex;
  padding: 5px;
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
  z-index: 9;
  position: relative;
  //box-shadow: 0 0 10px 0 rgb(0 0 0 / 20%);
  //border-radius: 5px;
  //padding: 10px 20px;
  background: white;
  border-radius: 1rem;
  border: 0px solid rgba(0, 0, 0, 0.125);
  box-shadow: rgb(0 0 0 / 5%) 0rem 1.25rem 1.6875rem 0rem;
`;
export const BabLink = styled.a`
  font-size: ${smallFontSize};
  line-height: 1.2;
  font-weight: 500;
  margin-left: 10px;
  cursor: pointer;
  font-family: ${firstFont};
  color: ${firstColor};
  text-decoration: none;
  display: flex;
  align-items: center;

  @media (max-width: 700px) {
    margin-left: 30px;
    margin-top: 5px;
  }
`;

export const Sign = styled.img.attrs({
  src: `${signImg}`,
})`
  width: 115px;
  margin-top: 5px;
  margin-left: 65px;
`;

export const BabBadge = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 15px;
  color: white;
  background-color: ${firstColor};
  font-size: 18px;
  border-radius: 5px;
  font-weight: 400;
  cursor: default;
`;

export const BabTag = styled(BabBadge)`
  font-weight: 400;
  height: fit-content;
  background-color: ${fourthColor};
  border-radius: 5px;
  font-size: ${smallFontSize};
`;

export const RowTagTable = styled.div`
  display: flex;
  gap: 5px;

  flex-wrap: wrap;
  width: 100%;
  flex-direction: row;

  @media (max-width: 600px) {
    // flex-direction: column;
  }
`;
export const BabButtonSmall = styled.span`
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  font-size: ${smallFontSize};
  padding: 0px 15px;
  cursor: pointer;
  font-weight: 400;
  transition: all 0.5s ease;
  &:hover {
    filter: brightness(1.1);
  }
  &:active {
    transform: translateY(0.5rem);
  }

  &:disabled {
    background-color: #ececec;
    color: #969696;
    cursor: not-allowed;
  }
`;

export const BabButton = styled.button`
  height: 50px;
  min-width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  width: 100%;
  background-color: ${secondColor};
  color: white;
  margin: 0px;
  padding: 15px 9px;
  font-size: medium;
  font-family: ${firstFont};
  outline: none;
  cursor: pointer;
  border: none;
  margin-top: 30px;
  margin-bottom: 40px;
  font-weight: 400;
  transition: all 0.5s ease;

  &:hover {
    filter: brightness(1.2);
  }
  &:active {
    transform: translateY(0.5rem);
  }

  &:disabled {
    background-color: #ececec !important;
    color: #969696;
    cursor: not-allowed;
    filter: brightness(1) !important;
  }
`;

export const RowAlways = styled(Row)`
  //width: 400px;
  align-items: center;

  @media (max-width: 900px) {
    flex-direction: row;
    width: 100%;
    max-width: 500px;
  }
`;
export const ContainerSaveButtons = styled.div`
  transition: all 0.5s ease-out;
  align-items: center;
  overflow: hidden;
  justify-content: flex-end;
  height: 45px;
  position: sticky;
  bottom: 5px;
  max-width: 300px;
  z-index: 1200;
  background: white;
  width: 50%;
  padding: 30px;
  box-shadow: rgba(0, 0, 0, 0.15) 0rem 1.25rem 1.6875rem 0rem;
  border-radius: 1rem;
  display: flex;
  left: 100%;
  @media (max-width: 700px) {
    flex-direction: row;
    width: 100%;
    //transform: translate(5%, 0);
    max-width: unset;
    justify-content: space-between;
  }
  animation: slideUp 0.2s 0s both;
  @-webkit-keyframes slideUp {
    0% {
      transform: translateY(900px);
    }
    100% {
      transform: translateY(0);
    }
  }
  @-moz-keyframes slideUp {
    0% {
      transform: translateY(900px);
    }
    100% {
      transform: translateY(0);
    }
  }
  @keyframes slideUp {
    0% {
      transform: translateY(900px);
    }
    100% {
      transform: translateY(0);
    }
  }
`;

export const BabWallpaper = styled.div`
  width: 100%;
  z-index: 1;
  @media (max-width: 700px) {
    display: none;
  }
  &:after {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 1;
    width: 313px;
    height: 461px;
    content: url(${WallPaperRight});
  }

  &:before {
    position: absolute;
    top: 13px;
    left: 0;
    z-index: 1;
    width: 310px;
    height: 477px;
    content: url(${WallPaperLeft});
  }
`;

export default GlobalStyles;
