import { useEffect, useState, useContext } from "react";
import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Routes, Route, useNavigate } from "react-router-dom";
import LandingPage from "./pages/landing";
import SignIn from "./pages/signIn";
import SignUp from "./pages/signUp";
import Blog from "./pages/blogPage";
import LandingBlog from "./pages/blogPage/LandingBlog";
import Appbar from "./components/appbar";
import ContactPage from "./components/contact/Contact1"
import NotFound from "./pages/notfound";

import AuthenticatedRoutes from "./components/AuthenticatedRoutes";
import { useAuth } from "./utils/AuthContext";
import { Circles } from "react-loader-spinner";



const App = () => {
  const [theme, colorMode] = useMode();
  const { user, setUser, isLoadingAuthContext, setIsLoadingAuthContext } = useAuth();

  const [userLoggedIn, setUserLoggedIn] = useState(true); // You can replace this with your actual authentication state
  // const [logoutTimer, setLogoutTimer] = useState(null);
  // // const INACTIVITY_TIMEOUT = 600000; // 10 minutes (in milliseconds)
  // const INACTIVITY_TIMEOUT = 60000; // 10 minutes (in milliseconds)
  // const navigate = useNavigate();

  // useEffect(() => {
  //   if (userLoggedIn) {
  //     // Reset the logout timer whenever there's user activity
  //     resetLogoutTimer();

  //     // Add event listeners to track user activity
  //     document.addEventListener('mousemove', resetLogoutTimer);
  //     document.addEventListener('keypress', resetLogoutTimer);
  //   } else {
  //     // Clear the logout timer and remove event listeners when the user logs out
  //     clearTimeout(logoutTimer);
  //     document.removeEventListener('mousemove', resetLogoutTimer);
  //     document.removeEventListener('keypress', resetLogoutTimer);
  //   }

  //   // Clean up event listeners when the component unmounts
  //   return () => {
  //     document.removeEventListener('mousemove', resetLogoutTimer);
  //     document.removeEventListener('keypress', resetLogoutTimer);
  //   };
  // }, [userLoggedIn, logoutTimer]);

  // const resetLogoutTimer = () => {
  //   // Clear the existing timer and set a new one
  //   clearTimeout(logoutTimer);
  //   const newLogoutTimer = setTimeout(logoutUser, INACTIVITY_TIMEOUT);
  //   setLogoutTimer(newLogoutTimer);
  // };

  // const logoutUser = () => {
  //   // Perform the logout action here, e.g., redirect to the logout page or clear user data
  //   setUserLoggedIn(false);
  //   // You can customize the logout action based on your application's requirements
  //   setUser(null);
  //   // 
  //   localStorage.removeItem('_MOMAII_AUTH_LOGGED_IN_TOKEN')
  //   localStorage.removeItem('_MOMAII_AUTH_LOGGED_IN_REFRESH_TOKEN')
  //   navigate("/")
  //   window.location.reload(true)
  // };


  if (!isLoadingAuthContext ) {
    // if (userLoggedIn) {
      return (
        // <AuthProvider>
          <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <div style={{ height: "100%", width: "100%" }}>
                <main>
                  <Routes>
                    <Route path="/" element={<LandingPage />} />
                    <Route path="/landing" element={<LandingPage />} />
                    <Route path="/sign-in" element={<SignIn />} />
                    <Route path="/sign-up" element={<SignUp />} />
                    <Route path="/appbar" element={<Appbar />} />
                    <Route path="/blog" element={<Blog />} />
                    <Route path="/lblog" element={<LandingBlog />} />
                    <Route path="/contact" element={<ContactPage />} />
                    <Route path="/dashbord/*" element={<AuthenticatedRoutes />} />
                    <Route path="*" element={<NotFound />} />
                  </Routes>
                </main>
              </div>
            </ThemeProvider>
          </ColorModeContext.Provider>
        // </AuthProvider>
      );
    // }
  } else {
      return(
          <div>
              <Circles
                  height="80"
                  width="80"
                  color="#4fa94d"
                  ariaLabel="circles-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
              />
          </div>
      )
  }
};
export default App;
