import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
// import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ProgressBar from './ProgressBar';
import { Box, Typography, useTheme } from '@mui/material';
import { tokens } from '../theme';


const ProjectCard = ({ project, onClickAction}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCampaignStarted, setIsCampaignStarted] = useState(false);
  const [isCampaignEnd, setIsCampaignEnd] = useState(false);
  const [amountCollectedPercentage, setAmountCollectedPercentage] = useState(0);

  const navigate = useNavigate();

  const redirectToProjectDetails = () => {
    // navigate to project id
    navigate(`${project._id}`);
  }

  useEffect(() => {
    const launchDate = new Date(project.launchDate);
    const currentDate = Date.now()
    if ( currentDate > launchDate.getTime()) {
      setIsCampaignStarted(true);
    };
    computePourcentageCollectedAmount(project.collectedAmount, project.targetAmount)
  }, [project.launchDate, project.collectedAmount, project.targetAmount]);

  const computePourcentageCollectedAmount = (x, y) => {
    setAmountCollectedPercentage(Math.round((x/y)*100))
  }

  function formatDate(date) {
    // take date like yyyy/mm/dd or yyyy-mm-dd or dd/mm/yyyy and return yyyy-mm-dd
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  function getMonthsBetweenDates(startDate, endDate) {
    var start = new Date(startDate);
    var end = new Date(endDate);
  
    var months = (end.getFullYear() - start.getFullYear()) * 12;
    months -= start.getMonth() + 1;
    months += end.getMonth();
  
    return months;
  }

  return (
    <Card sx={{ display: 'flex', maxWidth: 400, background: colors.primary[400]}}>
        <CardMedia
            component="img"
            sx={{ width: 80 }}
            image={project ? project.projetPicture : ""}
            alt="Card Image"
        />
        <CardContent sx={{ flex: 1 }}>
            <Typography variant="h6" onClick={redirectToProjectDetails} style={{ cursor: 'pointer' }}>{project ? project.projectName : "fak name"}</Typography>
            {/* affichage conditionnel */}
            {isCampaignEnd ? 
              <Typography variant="h6">{project ? formatDate(project.endDate) : formatDate("2025-12-31")}</Typography> :
              <Box>
                {isCampaignStarted ?
                  <Box>
                    <Box justifyContent={'space-between'} sx={{display:"flex"}}>
                      <Typography variant="h6">{project ? project.status : "not set"}</Typography>
                      <Typography variant="h6">{project ? `${amountCollectedPercentage} %` : `${50} %`}</Typography>
                    </Box>
                    <ProgressBar value={amountCollectedPercentage} />
                  </Box>
                  :
                  <Typography variant="h6">{project ? `Début le ${formatDate(project.launchDate)}` : formatDate("2023-09-20")}</Typography>
                }
              </Box>
            }
            <Box sx={{display:"flex", marginTop:"5px"}} >
              <Typography variant="h6" >
                {project ? `${project.targetAmount} fcfa` : `${1000000} fcfa`}
              </Typography>
              <Divider sx={{m: "0 5px 0 5px"}} orientation="vertical" flexItem />
              <Typography
                variant="h6"
              >
                {project ? `${getMonthsBetweenDates(project.launchDate, project.endDate)} mois` : `${5} mois`}
              </Typography>
              <Divider sx={{m: "0 5px 0 5px"}} orientation="vertical" flexItem />
              <Typography
                variant="h6"
              >
                {project ? `${project.interestRate} %` : `${5} %`}
              </Typography>
            </Box>
        </CardContent>
    </Card>
  );
};

export default ProjectCard;
