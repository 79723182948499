import { Box, useTheme, useMediaQuery } from "@mui/material";
import { tokens } from "../../theme";
import { mockTransactions } from "../../data/mockData";


import Appbar from "../../components/appbar";
import Barner from "../../components/Barner/index";
import LandingBlog from "../blogPage/LandingBlog";
import Footer from "../../components/footer/Footer2"


const LandingPage = () => {
  return (
    <Box m="20px">
      <Appbar />
      <Barner />
      <LandingBlog />
      <Footer />
    </Box>
  );
};

export default LandingPage;
