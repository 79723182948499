import React from "react";
import { useDropzone } from "react-dropzone";
import { FiUploadCloud } from "react-icons/fi";
import { BabText } from "../../Globalstyles";

export default function BabDropZone(props) {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: {
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx", ".xls"],
      "text/csv": [".csv", ".txt"],
      "image/*": [".jpeg", ".png"],
      "application/pdf": [".pdf"],
    },
    onDrop: (acceptedFiles) => {
      props.setFile(acceptedFiles[0]);
      console.log(acceptedFiles[0]);
    },
  });

  const files = acceptedFiles.map((file) => <li key={file.path}>{file.path}</li>);

  return (
    <section className="container-dropzone">
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        <BabText>
          <FiUploadCloud style={{ marginRight: "30px", fontSize: "20px" }} /> Glissez et déposez un fichier 
          ou cliquez pour choisir un fichier
        </BabText>
      </div>
    </section>
  );
}
