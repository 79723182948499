import { Box } from "@mui/material";
import { Link } from "react-router-dom";


const NotFound = () => {
    return (
        <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        >
            <h1>Oops! You seem to be lost.</h1>
            <p>Here are some helpful links:</p>
            <Link to='/'>Landing</Link>
        </Box>
    )
}

export default NotFound;