import React from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Avatar,
  Box,
  Button,
  Grid,
  Container,
  CardActions,
} from "@mui/material";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import ImageAvatar from "../../assets/img/team-1.jpg";
import { cards } from "./index";
import Image from "../../assets/img/page-title-bg.jpg";

const LandingBlog = () => {
  const firstThreeCards = cards.slice(0, 3);

  return (
    <Container
      maxWidth="lg"
      sx={{
        paddingTop: "2em",
      }}
    >
      <Grid container spacing={3} sx={{ marginBottom: "2em" }}>
        {firstThreeCards.map((card, index) => (
          <Grid key={index} item xs={12} sm={6} md={4}>
            <Card sx={{ maxWidth: 345, height: 400 }}>
              <CardMedia
                component="img"
                alt={card.title}
                height="140"
                image={Image}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {card.title}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{
                    display: "-webkit-box",
                    overflow: "hidden",
                    WebkitLineClamp: 4,
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  {card.description}
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small">Share</Button>
                <Button size="small">Learn More</Button>
              </CardActions>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  height: "70px",
                  margin: "1em 1em",
                }}
              >
                <Box sx={{ display: "flex" }}>
                  <Avatar alt="Remy Sharp" src={ImageAvatar} />
                  <Box ml={2}>
                    <Typography variant="subtitle2" component="p">
                      Evariste Siallou
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      color="textSecondary"
                      component="p"
                    >
                      August 15, 2023
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <BookmarkBorderIcon />
                </Box>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default LandingBlog;
