import axios from "axios";
import jwtDecode from 'jwt-decode';
import { getValueFromLocalStorage } from "./Utils";


const userToken = getValueFromLocalStorage('_MOMAII_AUTH_LOGGED_IN_TOKEN', null)


const MmfApi = {
    getToken: async function(data) {
        try {
            return await axios
            .post(`${process.env.REACT_APP_BACKEND_URL}/auth/login`, data, {
                headers: {
                    "Content-Type": "application/json"
                },
            });
        } catch (error) {
            return error
        }
    },
    
    refreshToken: async function (token) {
        try {
            const headers = {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`,
            }
            return await axios
                .post(`${process.env.REACT_APP_BACKEND_URL}/auth/refresh`, null, {
                    headers: headers
                });
        } catch (error) {
            console.log(error);
            return error
        }
    },

    signUp: async function(data) {
        try {
            return await axios
            .post(`${process.env.REACT_APP_BACKEND_URL}/register`, data, {
                headers: {"Content-Type": "application/json"},
            });
        } catch (error) {
            return error
        }
    },

    userMe: async function (token) {
        try {
            const headers = {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`,
            }
            return await axios
                .get(`${process.env.REACT_APP_BACKEND_URL}/user`, {
                    headers: headers
                });
        } catch (error) {
            console.log(error);
            return error
        }
    },

    updateUser: async function(token, data) {
        try {
            const headers = {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`,
            }
            return await axios
            .patch(`${process.env.REACT_APP_BACKEND_URL}/user`, data, {
                headers: headers
            });
        } catch (error) {
            return error
        }
    },

    uploadFile: async function (file, fileType) {
        console.log("***** API upload file to storage:", fileType);
        const headers = {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${userToken}`,
        }
        const formData = new FormData();
        console.log("FFFFFFF FILE :", file);
        formData.append("file", file);
        console.log("FFFFFFF FORM-DATA :", formData);
    
        return axios
          .post(
            `${process.env.REACT_APP_BACKEND_URL}/user/files?fileType=${fileType}`,
            formData,
            {
              headers: { ...headers, "Content-Type": "multipart/form-data" },
            }
          )
          .catch(function (error) {
            console.log(error);
            return error;
          });
    },

    getAllProjects: async function (token) {
        try {
            const headers = {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`,
            }
            return await axios
                .get(`${process.env.REACT_APP_BACKEND_URL}/project`, {
                    headers: headers
                });
        } catch (error) {
            console.log(error);
            return error
        }
    },
}

export default MmfApi;