import React, { useState } from "react";
import {
  AppbarContainer,
  AppbarHeader,
} from ".";
import {
  ListItemText,
  ListItemButton,
  Box,
  Button,
  useTheme,
  Drawer,
  List,
  Divider,
  IconButton,
Typography
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import {useNavigate } from "react-router-dom";
import { tokens } from "../../theme";

const MobileAppbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const handleMenuOpen = () => {
    setMenuOpen(true);
  };

  const handleMenuClose = () => {
    setMenuOpen();
  };

  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen);
  };

  const menuItems = [
    { label: "Projets", path: "/projects" },
    { label: "Guide", path: "/guide" },
    { label: "Investir dans un projet", path: "/invest" },
    { label: "Blog", path: "https://momaii-blog-front-athl6nvcma-ew.a.run.app" },
  ];

  const menuButtons = [
    {
      label: "Inscription",
      onClick: () => navigate("/sign-up"),
    },
    {
      label: "Connexion",
      onClick: () => navigate("/sign-in"),
    },
  ];

  return (
    <AppbarContainer>
      <IconButton onClick={handleMenuToggle}>
        {menuOpen ? <CloseIcon /> : <MenuIcon />}
      </IconButton>
      <AppbarHeader
        textAlign={"center"}
        variant="h4"
        onClick={handleMenuToggle}
        style={{ cursor: "pointer" }}
      >
        MOMAIIFUNDING
      </AppbarHeader>
      <Drawer anchor="top" open={menuOpen} onClose={handleMenuClose}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          padding="16px"
          margin="20px"
          sx={{
            backgroundColor: "beige",
          }}
        >
          {/* Ajoutez l'icône "X" pour fermer le menu */}
          <IconButton
            onClick={handleMenuClose}
            style={{ alignSelf: "flex-start" }}
          >
            <CloseIcon />
          </IconButton>

          <AppbarHeader
            textAlign={"center"}
            variant="h4"
            onClick={handleMenuToggle}
          >
            MOMAIIFUNDING
          </AppbarHeader>
          <Divider />
          <List>
            {menuItems.map((item, index) => (
              <ListItemButton
                sx={{
                  textAlign: "center",
                }}
                key={index}
                onClick={() => {
                  handleMenuClose();
                  navigate(item.path);
                }}
                href={item.path}
              >
                <ListItemText primary={item.label} />
              </ListItemButton>
            ))}
          </List>
          <Divider />

          <Box display="flex" flexDirection="column" alignItems="center">
            {menuButtons.map((button, index) => (
              <Button
                key={index}
                variant="contained"
                sx={{
                  backgroundColor: colors.blueAccent[700],
                  color: colors.grey[100],
                  fontSize: "14px",
                  fontWeight: "bold",
                  padding: "10px 20px",
                  margin: "5px 0",
                  width: "100%",
                  height: "40px",
                }}
                onClick={() => {
                  handleMenuClose();
                  button.onClick();
                }}
              >
                {button.label}
              </Button>
            ))}
          </Box>
        </Box>
      </Drawer>
    </AppbarContainer>
  );
};

export default MobileAppbar;
