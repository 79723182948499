import React, {useContext, useState, useEffect} from 'react'
import FormProfile from '../../components/FormProfile';
import EditUserProfile from '../../components/EditUserProfile';
import ImmDropZone from '../../components/ImmDropZone';
import { Box } from '@mui/material';
import Header from '../../components/Header';



const Profile = () => {

  return (
    <Box m="20px">
      <Header title="Mon profile" subtitle="Les informations renseignées sur votre profil sont nécessaires afin de valider votre compte et ainsi de pouvoir investir dans les projets de votre choix." />

      <Box>
        < FormProfile />
        {/* < EditUserProfile /> */}
      </Box>
    </Box>
    );
}

export default Profile