import React from "react";
import { AppbarHeader, AppbarContainer, Mylist } from ".";
import {
  ListItemText,
  Box,
  Button,
  useTheme,
  List,
  ListItemButton,
  Divider,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { tokens } from "../../theme";
import Blog from "../../pages/blogPage";

const DesktopAppbar = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const menuItems = [
    { label: "Projets", path: "/projects" },
    { label: "Guide", path: "/guide" },
    { label: "Investir dans un projet", path: "/invest" },
    { label: "Blog", path: "https://momaii-blog-front-athl6nvcma-ew.a.run.app" },
  ];

  return (
    <>
      <AppbarContainer>
        <AppbarHeader> MOMAIIFUNDING</AppbarHeader>

        <Divider />
        <List
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          {menuItems.map((item, index) => (
            <ListItemButton
              sx={{
                textAlign: "center",
              }}
              key={index}
              // onClick={() => {
              //   navigate(item.path);
              // }}
              href={item.path}
            >
              <ListItemText primary={item.label} />
            </ListItemButton>
          ))}
        </List>
        <Divider />

        <Mylist type="row">
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
              margin: "0px 50px",
            }}
            onClick={() => navigate("/sign-up")}
          >
            Inscription
          </Button>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
            onClick={() => navigate("/sign-in")}
          >
            Connexion
          </Button>
        </Mylist>
      </AppbarContainer>
    </>
  );
};

export default DesktopAppbar;
