import React, { useState, useEffect } from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Box,
} from '@mui/material';

import { 
  BabBadge,
  RowAlways,
  thirdColor,
  BabButton,
  fourthColor,
  BabLabel,
  Row,
  firstFont,
  smallFontSize,
  Col,
  BabLink,
  secondColor,
  BabText,
  BabRadius,
 } from '../Globalstyles';
import { useTheme } from '@mui/material';
import { Oval } from "react-loader-spinner";
import { HiOutlineRocketLaunch } from "react-icons/hi2";
import { FiAlertTriangle, FiCheck, FiInfo, FiXCircle } from "react-icons/fi";
import { tokens } from '../theme';
import BabDropZone from './BabDropZone';
import MmfApi from '../utils/MmfApi';
import { notify } from '../utils/notify';


const FormUploadFile = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [selectedFileType, setSelectedFileType] = useState('');
  const [file, setFile] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [checkingFile, setCheckingFile] = useState(false);
  const [ingestionError, setIngestionError] = useState([]);
  const [fileCheckedSuccessfully, setFileCheckedSuccessfully] = useState(false);
  const [loadingSendFile, setLoadingSendFile] = useState(false);
  const [fileError, setFileError] = useState(false);
  


  const fileTypes = [
    {"_id": "id-card", name:"carte d'identité"}, {"_id": "passport", name:"passeport"}
  ]


  const handleSendFile = async () => {
    setLoadingSendFile(true);
    const response = await MmfApi.uploadFile(file, selectedFileType)
    if (response.status === 202) {
      clear();
      setSelectedFileType('');
      notify("Document envoyé avec sucèss !", "success", true);
    } else {
      setIngestionError([
        {
          type: "technical",
          message: `Technical error ${response.message ? ": " + response.message : ""}`,
        },
      ]);
      setFileCheckedSuccessfully(false);
      notify("Error technique", "error");
    }

    setLoadingSendFile(false);
  };


  const clear = () => {
    setFileCheckedSuccessfully(false);
    setFile();
    setIngestionError([]);
    // setFileError(false);
    // setStepOnError(-1);
  };

  const checkSlectedFile = async () => {
    setCheckingFile(true);
    await new Promise((r) => setTimeout(() => r(), 1000));
    // call check function
    // const response = await API.checkFile(file, maskSelected);
    setFileCheckedSuccessfully(true);

    // if (response.status === 202) {
    //   console.log(response.data);
    //   if (
    //     response.data.contentControlError?.length > 0 ||
    //     response.data.metadataControlError?.length > 0
    //   ) {
    //     var errors = [];
    //     response.data.contentControlError.map((item, index) => {
    //       console.log({ type: "content", message: item.error });
    //       errors.push({ type: "content", message: item.error });
    //       notify(item.error, "error");
    //     });

    //     response.data.metadataControlError.map((item, index) => {
    //       console.log({ type: "metadata", message: item.error });
    //       errors.push({ type: "metadata", message: item.error });
    //       notify(item.error, "error");
    //     });
    //     setIngestionError(errors);
    //     setFileCheckedSuccessfully(false);
    //     setStepOnError(1);
    //   } else {
    //     setFileCheckedSuccessfully(true);
    //     setActiveStep(2);
    //   }
    // } else {
    //   setIngestionError([
    //     {
    //       type: "technical",
    //       message: `Technical error ${response.message ? ": " + response.message : ""}`,
    //     },
    //   ]);
    //   setFileCheckedSuccessfully(false);
    //   setStepOnError(1);
    //   console.log(response.status, response.data);
    //   notify("Technical error", "error");
    // }

    setCheckingFile(false);
  };

  useEffect(() => {
    if (file) {
      console.log("checking!");
      checkSlectedFile();
    }
  }, [file]);

  return (
    <>
      <BabRadius
        style={{
          width: "100%",
          zIndex: "2",
          margin: "20px 0px 0px 0px",
          maxWidth: "720px",
          background: colors.primary[400],
        }}
      >
        <RowAlways>
          <FiInfo
            style={{
              fontSize: "30px",
              marginRight: "20px",
              color: fourthColor,
              minWidth: "20px",
            }}
          />
          <BabText
            style={{
              color: colors.grey[100],
            }}
          >
            Selectionnez le type de fichier que vous souhaitez charger, et patientez un tout petit peu, pour des vérifications d'usage.
            Si les vérifications sont Ok et pas d'erreur, alors cliquez sur le bouton Envoyer pour charger votre document
          </BabText>
        </RowAlways>
      </BabRadius>


      <Col style={{ zIndex: "2", maxWidth: "700px", gap: "20px", marginTop: "50px" }}>
        <Row
          style={{
            marginTop: "40px",
            justifyContent: "space-between",
            gap: "20px",
          }}
        >
          <FormControl sx={{ width: "100%" }}>
            <InputLabel id="type-de-document" >type de document</InputLabel>
            
            <Select
              sx={{
                fontFamily: firstFont,
                fontSize: smallFontSize,
                fontWeight: "500",
                color: colors.background,
                width: "100%",
                background: colors.primary[400],
              }}
              value={selectedFileType}
              onChange={(e) => {
                setSelectedFileType(e.target.value);
              }}
              name="type"
              label="type de document"
              disabled={Boolean(file)}
            >
              <MenuItem value="" >
                <em>None</em>
              </MenuItem>
              {fileTypes.map((item, index) => {
                return (
                  <MenuItem
                    key={index}
                    value={item._id}
                    sx={{
                      fontFamily: firstFont,
                      fontSize: smallFontSize,
                      fontWeight: "500",
                      color: colors.background,
                    }}
                  >
                    {`${item.name}`}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Row>

        {selectedFileType && !file && <BabDropZone setFile={setFile} />}
        {file && (
          <>
            <BabBadge
              style={{
                background: ingestionError.length > 0 ? "#ff7e63ab" : fourthColor,
                border: ingestionError.length > 0 ? `2px solid ${secondColor}` : "none",
                fontSize: smallFontSize,
                padding: "10px 15px",
                height: "53px",
                minWidth: "300px",
                justifyContent: "space-between",
              }}
            >
              {checkingFile && (
                <Oval
                  height={25}
                  width={25}
                  color="white"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="#white"
                  strokeWidth={2}
                  strokeWidthSecondary={2}
                />
              )}
              {ingestionError.length > 0 && !checkingFile && (
                <FiAlertTriangle
                  style={{ fontSize: "25px", cursor: "pointer" }}
                  onClick={() => {
                    setOpenDialog(true);
                  }}
                />
              )}
              {!ingestionError.length > 0 && !checkingFile && (
                <FiCheck style={{ fontSize: "25px", cursor: "pointer" }} />
              )}
              <span
                style={{
                  maxWidth: "90%",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  marginLeft: "10px",
                }}
              >
                {checkingFile ? "checking file ..." : file.name}
              </span>

              {checkingFile ? (
                <div></div>
              ) : (
                <FiXCircle
                  onClick={() => {
                    clear();
                  }}
                  style={{ marginLeft: "10px", cursor: "pointer", fontSize: "25px" }}
                />
              )}
            </BabBadge>
            {ingestionError.length > 0 && (
              <Row>
                <BabLabel>Oops, qu'est ce qui n'a pas marché ?: </BabLabel>
                <BabLink
                  onClick={() => {
                    setOpenDialog(true);
                  }}
                  style={{ color: secondColor, textDecoration: "underline dotted" }}
                >
                  voir le fond du problème
                </BabLink>
              </Row>
            )}

            {fileCheckedSuccessfully && file && (
              <BabButton
                disabled={fileError || checkingFile}
                onClick={handleSendFile}
                style={{
                  width: "auto",
                  margin: "0px",
                  padding: "5px 15px",
                  minWidth: "100px",
                  height: "53px",
                  background: colors.blueAccent[700],
                }}
              >
                {loadingSendFile ? (
                  <Oval
                    height={25}
                    width={25}
                    color="white"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="#white"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                  />
                ) : (
                  <>
                    <HiOutlineRocketLaunch
                      style={{ marginRight: "30px", fontSize: "25px" }}
                    />
                      Envoyer
                    <HiOutlineRocketLaunch
                      style={{ marginLeft: "30px", fontSize: "25px" }}
                    />
                  </>
                )}
              </BabButton>
            )}
          </>
        )}
      </Col>


    </>
  );
};

export default FormUploadFile;
