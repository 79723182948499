// docs https://github.com/azouaoui-med/react-pro-sidebar
import { useState, useContext } from "react";
import { Menu, Sidebar, MenuItem } from "react-pro-sidebar";
import { useProSidebar } from "react-pro-sidebar";

import { useSidebarContext } from "./sidebarContext";

import { Link } from "react-router-dom";
import { tokens } from "../../../theme";
import { useTheme, Box, Typography, IconButton } from "@mui/material";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import CampaignIcon from '@mui/icons-material/Campaign';
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import PieChartOutlineOutlinedIcon from "@mui/icons-material/PieChartOutlineOutlined";
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import NoteAltOutlinedIcon from '@mui/icons-material/NoteAltOutlined';

import { FiLogOut, FiSend, FiUser } from "react-icons/fi";

import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import SwitchRightOutlinedIcon from "@mui/icons-material/SwitchRightOutlined";
import SwitchLeftOutlinedIcon from "@mui/icons-material/SwitchLeftOutlined";


const sidebarItems = [
  {
    typo: "General", 
    items:[
      {to: "/dashbord", icon: HomeOutlinedIcon, title:"Tableau de board"},
      {to: "/dashbord/projets", icon: BusinessCenterIcon, title:"Les projets"},
      {to: "/dashbord/subscriptions", icon: CardMembershipIcon, title:"Mes Souscriptions"},
      {to: "/dashbord/transactions", icon: AccountBalanceWalletIcon, title:"Mes Trasactions"},
    ]
  },
  {
    typo: "Valider mon compte", 
    items: [
      {to: "/dashbord/profile", icon: PersonOutlinedIcon, title:"Mon profile"},
      {to: "/dashbord/supporting-documentation", icon: CloudUploadOutlinedIcon, title:"Vos pièces justificatives"},
      {to: "/dashbord/contacts", icon: NoteAltOutlinedIcon, title:"questionnaire d'adéquation"},
    ]
  },
  {
    typo: "Divers", 
    items: [
      {to: "/dashbord/actualites", icon: CampaignIcon, title:"Mes Actualités"},
      {to: "/dashbord/contacts", icon: NoteAltOutlinedIcon, title:"Nous vous écoutons"},
    ]
  },
]

const Item = ({ title, to, icon, selected, setSelected, broken, toggleSidebar, collapseSidebar }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <MenuItem
      active={selected === title}
      style={{ color: colors.grey[100] }}
      onClick={() => {
        setSelected(title);
        // {broken ? toggleSidebar() : collapseSidebar()}
      }}
      icon={icon}
      routerLink={<Link to={to} />}
    >
      <Typography>{title}</Typography>
    </MenuItem>
  );
};

const MyProSidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selected, setSelected] = useState("Dashboard");
  const { sidebarRTL, setSidebarRTL, sidebarImage, user } = useSidebarContext();
  const { collapseSidebar, toggleSidebar, collapsed, broken } = useProSidebar();


  return (
    // box contenant la sidebar un peu comme une div ou container
    <Box>
      <Box
        sx={{
          position: "sticky",
          display: "flex",
          height: "100vh",
          top: 0,
          bottom: 0,
          zIndex: 10000,
          "& .sidebar": {
            border: "none",
          },
          "& .menu-icon": {
            backgroundColor: "transparent !important",
          },
          "& .menu-item": {
            // padding: "5px 35px 5px 20px !important",
            backgroundColor: "transparent !important",
          },
          "& .menu-anchor": {
            color: "inherit !important",
            backgroundColor: "transparent !important",
          },
          "& .menu-item:hover": {
            color: `${colors.blueAccent[500]} !important`,
            backgroundColor: "transparent !important",
          },
          "& .menu-item.active": {
            color: `${colors.greenAccent[500]} !important`,
            backgroundColor: "transparent !important",
          },
        }}
      >
        
        <Sidebar
          breakPoint="md"
          rtl={sidebarRTL}
          backgroundColor={colors.primary[400]}
          image={sidebarImage}
          width="300px"
        >
          <Menu iconshape="square">
            <MenuItem
              icon={
                collapsed ? (
                  <MenuOutlinedIcon onClick={() => collapseSidebar()} />
                ) : sidebarRTL ? (
                  <SwitchLeftOutlinedIcon
                    onClick={() => setSidebarRTL(!sidebarRTL)}
                  />
                ) : (
                  <SwitchRightOutlinedIcon
                    onClick={() => setSidebarRTL(!sidebarRTL)}
                  />
                )
              }
              style={{
                margin: "10px 0 20px 0",
                color: colors.grey[100],
              }}
            >
              {!collapsed && (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  ml="0px"
                >
                  <Typography variant="h3" color={colors.grey[100]}>
                    MomaiiFunding
                  </Typography>
                  <IconButton
                    onClick={
                      broken ? () => toggleSidebar() : () => collapseSidebar()
                    }
                  >
                    <CloseOutlinedIcon />
                  </IconButton>
                </Box>
              )}
            </MenuItem>
            {!collapsed && (
              <Box mb="25px">
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    "& .avater-image": {
                      backgroundColor: colors.primary[500],
                    },
                  }}
                >
                  {/* user and profilePictureUrl not null or undifined */}
                  {user && user.profilePictureUrl ?
                    <img
                      className="avater-image"
                      alt="profile user"
                      width="100px"
                      height="100px"
                      src={ user.profilePictureUrl }
                      style={{ cursor: "pointer", borderRadius: "50%" }}
                    />
                    :
                    <FiUser style={{width:"100px", height:"100px", cursor: "pointer", borderRadius: "50%" }} />
                  }

                </Box>
                <Box textAlign="center">
                  <Typography
                    variant="h3"
                    color={colors.grey[100]}
                    fontWeight="bold"
                    sx={{ m: "10px 0 0 0" }}
                  >
                    {/* {JSON.stringify(user)} */}
                    {user.email}
                  </Typography>
                </Box>
              </Box>
            )}
            
            <Box paddingLeft={collapsed ? undefined : "10%"}>
              {sidebarItems.map((elms, id) => (
                <Box key={id}>
                {elms.typo ?
                  <Typography
                    variant="h6"
                    color={colors.grey[300]}
                    sx={{ m: "15px 20px 5px 20px" }}
                  >
                    {elms.typo}
                  </Typography> : null}

                {elms.items.map((item, id) => {
                  return (
                    <Box key={id}>
                      <Item
                        title={item.title}
                        to={item.to}
                        icon={<item.icon />}
                        selected={selected}
                        setSelected={setSelected}
                        broken={broken}
                        collapseSidebar={collapseSidebar}
                        toggleSidebar={toggleSidebar}
                      />
                    </Box>
                  )
                })}
                </Box>
              ))}
            </Box>
          </Menu>
        </Sidebar>
      </Box>
    </Box>
  );
};

export default MyProSidebar;
