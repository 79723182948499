import jwt_decode from 'jwt-decode';


export function getValueFromLocalStorage(key, defaultValue) {
    const saved = localStorage.getItem(key);
    const initial = JSON.parse(saved);
    return initial || defaultValue;
};


// export const checkIfTokenExpired = async (storedToken) => {
//     if (storedToken) {
//       // Décoder le token JWT pour obtenir les informations, y compris la date d'expiration
//       const decodedToken = jwt_decode(storedToken);    
//       // Obtenir la date d'expiration du token en secondes
//       const expirationTime = decodedToken.exp;
//       // Obtenir le temps actuel en secondes
//       const currentTime = Math.floor(Date.now() / 1000);
//       const expired = currentTime > expirationTime
//       if (expired) {
//         // attention clear all item set in localstorage
//         // localStorage.clear()
//         localStorage.removeItem('_MOMAII_AUTH_LOGGED_IN_TOKEN')
//       }
//       return expired
//     } else {
//       return false
//     }
//   }