import React from "react";
import { 
  Typography, 
  Box, 
  useTheme, 
  useMediaQuery} from "@mui/material";
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import Stack from '@mui/material/Stack';
import { tokens } from "../theme";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';



// const Item = styled(Paper)(({ theme }) => ({
//   backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
//   ...theme.typography.body2,
//   padding: theme.spacing(1),
//   textAlign: 'center',
//   color: theme.palette.text.secondary,
// }));


const Header = ({ title, subtitle }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const navigate = useNavigate();

  return (
    <Box
      display={smScreen ? "flex" : "block"}
      flexDirection={smScreen ? "row" : "column"}
      justifyContent={smScreen ? "space-between" : "start"}
      alignItems={smScreen ? "center" : "start"}
      m="10px 0"
    >

      <Box mb="30px" >
        <Typography
          variant="h2"
          color={colors.grey[100]}
          fontWeight="bold"
          sx={{ mb: "5px" }}
        >
          {title}
        </Typography>
        <Typography variant="h5" color={colors.greenAccent[400]}>
          {subtitle}
        </Typography>
      </Box>

      <Stack direction="row" spacing={2}>
        <Button 
          // variant="outlined" 
          sx={{
            backgroundColor: colors.blueAccent[700],
            color: colors.grey[100],
            fontSize: "14px",
            fontWeight: "bold",
          }}
          onClick={() => {
            navigate("/dashbord/projets");
          }}
        >
          Crediter mon portefeuille
        </Button>
        <Button 
          // variant="outlined"
          sx={{
            backgroundColor: colors.blueAccent[700],
            color: colors.grey[100],
            fontSize: "14px",
            fontWeight: "bold",
          }}
          onClick={() => {
            navigate("/dashbord/projets");
          }}
        >
          Debiter mon portefeuille
        </Button>
      </Stack>
    </Box>
  );
};

export default Header;
