import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  useTheme,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { tokens } from "../../../theme";
import Header from "../../../components/Header";
import { useAuth } from "../../../utils/AuthContext";
import { BabBox, BabHr } from "../../../Globalstyles";
import HorizontalRuleSharpIcon from '@mui/icons-material/HorizontalRuleSharp';


const ProjectDetails = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const location = useLocation();
  const { user, projects } = useAuth();
  const [project, setProject] = useState();
  const [notFound, setNotFound] = useState(false);
  const [loadingSetup, setLoadingSetup] = useState(true);
  

  useEffect(() => {
    if (projects) {
      // get project id from url path
      const projectId = location.pathname.split("/")[3];
      console.log("PROJECT ID :", projectId)
      // filter project id in projects list
      const res = projects.filter((item) => item._id == projectId);
      if (res.length === 0) {
        setNotFound(true);
      }
      setProject(res[0]);
      setLoadingSetup(false);
    }
  }, [projects]);

  const navigate = useNavigate();


  return (
    <Box m="20px">
      {/* HEADER */}
        <Header title="PROJETS" subtitle={project ? project.projectName : null} />
        
        <Grid
          container
          spacing={3}
          padding={2}
        >
          <Grid item xs={12} md={8}>
            <BabBox style={{background: colors.primary[400]}}>
              <Typography variant="h3">
                Présentation de l'entreprise
              </Typography>
              <HorizontalRuleSharpIcon />
              <Typography variant="body1" gutterBottom>
                body1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos
                blanditiis tenetur unde suscipit, quam beatae rerum inventore consectetur,
                neque doloribus, cupiditate numquam dignissimos laborum fugiat deleniti? Eum
                quasi quidem quibusdam.
              </Typography>
              <Typography variant="h3">
                Présentation du projet
              </Typography>
              <HorizontalRuleSharpIcon />
              <Typography variant="body1" gutterBottom>
                body1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos
                blanditiis tenetur unde suscipit, quam beatae rerum inventore consectetur,
                neque doloribus, cupiditate numquam dignissimos laborum fugiat deleniti? Eum
                quasi quidem quibusdam.
              </Typography>
              <Typography variant="h3">
                L'avis de MoMaiiFunding
              </Typography>
              <HorizontalRuleSharpIcon />
              <Typography variant="body1" gutterBottom>
                body1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos
                blanditiis tenetur unde suscipit, quam beatae rerum inventore consectetur,
                neque doloribus, cupiditate numquam dignissimos laborum fugiat deleniti? Eum
                quasi quidem quibusdam.
              </Typography>
            </BabBox>
          </Grid>
          <Grid item xs={12} md={4}>
            <BabBox style={{background: colors.primary[400]}}>
            <Typography variant="h3">
              En Bref
            </Typography>
            </BabBox>
          </Grid>
        </Grid>
    </Box>
  );
};

export default ProjectDetails;
