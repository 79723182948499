// import * as React from 'react';
import React, { useEffect, useState } from "react";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import MmfApi from '../../utils/MmfApi';
import { useNavigate, Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Window } from '@mui/icons-material';
import { useAuth } from '../../utils/AuthContext';




function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        MOMAII HOLDING
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}


const defaultTheme = createTheme();



export default function SignIn() {

    const navigate = useNavigate();
    const { login, getCurrentUser, isLoadingAuthContext, user } = useAuth();

    // useEffect(() => {
    //   const silentLogin = async () => {
    //     // Navigate dashbord page if user is connected
    //     console.log(user)
    //     if (!isLoadingAuthContext) {
    //       console.log(isLoadingAuthContext)
    //       if (user) {
    //         console.log(isLoadingAuthContext)
    //         navigate("/dashbord")
    //           // return <Navigate to="/dashbord" />;
    //       }
    //     }
    //   };

    //   silentLogin();
    // }, []);

    // Navigate dashbord page if user is connected
    if (!isLoadingAuthContext) {
      if (user) {
          return <Navigate to="/dashbord" />;
      }
    }


    const handleSignIn = async (event) => {
      event.preventDefault();
      // const data = new FormData(event.currentTarget);
      const formData = new FormData(event.currentTarget);
      // convert form data content to json object
      const data = {};
      formData.forEach((value, key) => (data[key] = value));
      // make post request with data 
      const response = await MmfApi.getToken(data);
      if (response.status === 200) {
        // console.log("++++++++++++++++ ",response.data)
        // user token
        const userToken = response.data.access_token
        const userData = await getCurrentUser(userToken);
        // set user data in auth context
        login(userData);
        // save token in local storage of user browser
        localStorage.setItem('_MOMAII_AUTH_LOGGED_IN_TOKEN', JSON.stringify(userToken));
        localStorage.setItem('_MOMAII_AUTH_LOGGED_IN_REFRESH_TOKEN', JSON.stringify(response.data.refresh_token));
        // Redirect to dashbord page
        navigate("/dashbord")
        window.location.reload(true)
        // return <Navigate to="/dashbord" />
      } else {
        toast.error("no accound found with these credentials")
      }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" onSubmit={handleSignIn} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2" onClick={()=>navigate("/sign-up")}>
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}