import React, { useEffect, useContext, useState } from "react";
import { Routes, Route, Navigate} from "react-router-dom";
import Topbar from "../pages/global/Topbar";
import Dashboard from "../pages/dashboard";
import ProjectDetails from "../pages/projects/ProjectDetails";
import ProjectList from "../pages/projects/ProjectList";
// import Projects from "../pages/projects";
// import ProjectDetails from "../pages/project-details";
import NotFound from "../pages/notfound";
import Subscriptions from "../pages/subscriptions";
import Transactions from "../pages/transactions";
import Actuality from "../pages/actuality";
import SupportingDocumentation from "../pages/supporting-documentation";
import Contacts from "../pages/contacts";
import Profile from "../pages/profile";
import FAQ from "../pages/faq";
import Invoices from "../pages/invoices";
import Calendar from "../pages/calendar";
import Geography from "../pages/geography";
import { MyProSidebarProvider } from "../pages/global/sidebar/sidebarContext";
import { useAuth } from "../utils/AuthContext";
import { Circles } from "react-loader-spinner";



function AuthenticatedRoutes() {
    const { user } = useAuth();
    

    if (!user) {
        return <Navigate to="/sign-in" />;
    }

    return (
        <MyProSidebarProvider>
        <div style={{ height: "100%", width: "100%" }}>
            <main>
            <Topbar /> 
            <Routes>
                <Route path="" element={<Dashboard />} />
                <Route path="/dashbord" element={<Dashboard />} />
                <Route path="/projets" element={<ProjectList />} />
                <Route path="/projets/:id" element={<ProjectDetails />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="subscriptions" element={<Subscriptions />} />
                <Route path="transactions" element={<Transactions />} />
                <Route path="/actualites" element={<Actuality />} />
                <Route path="/supporting-documentation" element={<SupportingDocumentation />} />
                <Route path="/faq" element={<FAQ />} />
                <Route path="/contacts" element={<Contacts />} />
                <Route path="/invoices" element={<Invoices />} />
                <Route path="/calendar" element={<Calendar />} />
                <Route path="*" element={<NotFound />} />
                <Route path="/calendar" element={<Calendar />} />
                <Route path="/geography" element={<Geography />} />
                {/*
                
                <Route path="/bar" element={<Bar />} />
                <Route path="/pie" element={<Pie />} />
                <Route path="/line" element={<Line />} /> */}
                {/* <Route path="*" element={<NotFound />} /> */}
            </Routes>
            </main>
        </div>
        </MyProSidebarProvider>
    )

}


export default AuthenticatedRoutes;