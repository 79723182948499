import React from "react";
import {
  Box,
  AppBar,
  Toolbar,
  Button,
  Typography,
  Avatar,
  useTheme,
  Grid,
  Card,
  Container,
  CardContent,
  Pagination,
  CardMedia,
  CardActions,
} from "@mui/material";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import { tokens } from "../../theme";
import Image from "../../assets/img/page-title-bg.jpg";
import ImageAvatar from "../../assets/img/team-1.jpg";

const cards = [
  {
    id: 1,
    title: "Card 1",
    description:
      " A laboriosam quos earum illo quaerat quae,cimus repellendus dolore aspernatur laborum commodi magnam qui necessitatibus accusantium error porro! Tenetur ex eos dolor similique laborum ab, perspiciatis nostrum sequi quos debitis nihil voluptatem sapiente suscipit nulla! Fugiat blanditiis, sequi at, inventore vel nesciunt, ad voluptatibus dolore ",
  },
  {
    id: 2,
    title: "Card 2",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. A laboriosam quos earum illo qua 2 A laboriosam quos earum s repellendus dolore aspernatur laborum commodi magnam qui nece",
  },
  {
    id: 3,
    title: "Card 3",
    description:
      " A laboriosam quos earum illo quaerat quae, ullam quia voluptatum ducimus repellendus dolore aspernatur laborum commodi magnam qui necessitatibus accusantium error porro! Tenetur ex eos dolor similique laborum ab, perspiciatis nostrum sequi quos debitis nihil voluptatem sapiente suscipit nulla! Fugiat blanditiis, sequi at, inventore vel nesciunt, ad voluptatibus dolore ",
  },
  {
    id: 4,
    title: "Card 4",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. A laboriosam quos earum illo qua3 A laboriosam quos earum s repellendus dolore aspernatur laborum commodi magnam qui nece",
  },
  {
    id: 5,
    title: "Card 5",
    description:
      " A laboriosam quos earum illo quaerat quae, ullam quia voluptatum ducimus repellendus dolore aspernatur laborum commodi magnam qui necessitatibus accusantium error porro! Tenetur ex eos dolor similique laborum ab, perspiciatis nostrum sequi quos debitis nihil voluptatem sapiente suscipit nulla! Fugiat blanditiis, sequi at, inventore vel nesciunt, ad voluptatibus dolore ",
  },
  {
    id: 6,
    title: "Card 6",
    description:
      "Lorem i, consectetur adipisicing elit. A laboriosam quos earum s repellendus dolore aspernatur laborum commodi magnam qui necessitatibus accusantium error porro! Tenetur ex eos dolor similique laborum ab, perspiciatis nostrum sequi quos debitis nihil voluptatem sapiente suscipit nulla! Fugiat blanditiis, sequi at, inventore vel nesciunt, ad voluptatibus dolore tempore ullam quae",
  },
];

const Blog = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <div>
      <AppBar position="static" sx={{ backgroundColor: "#fcfcfc" }}>
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h3" sx={{ color: colors.primary[700] }}>
            MOMAII-BLOG
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          backgroundImage: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${Image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          minHeight: "100px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "white",
          position: "relative",
          backgroundRepeat: "no-repeat",
          fontSize: "4em",
        }}
      >
        <Box>
          <Typography variant="h3">
            Le blog Ideal pour tous savoir sur l'immobilier en Afrique
          </Typography>
        </Box>
      </Box>
      <Container
        maxWidth="lg"
        sx={{
          paddingTop: "2em",
        }}
      >
        <Typography
          variant="h2"
          sx={{
            fontWeight: 800,
            paddingBottom: "2em",
          }}
        >
          Articles
        </Typography>
        <Grid container spacing={3} sx={{ marginBottom: "2em" }}>
          {cards.map((card, index) => (
            <Grid key={index} item xs={12} sm={6} md={4}>
              <Card sx={{ maxWidth: 345, height: 400 }}>
                <CardMedia
                  component="img"
                  alt={card.title}
                  height="140"
                  image={Image}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {card.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{
                      display: "-webkit-box",
                      overflow: "hidden",
                      WebkitLineClamp: 4,
                      WebkitBoxOrient: "vertical",
                    }}
                  >
                    {card.description}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small">Share</Button>
                  <Button size="small">Learn More</Button>
                </CardActions>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    height: "70px",
                    margin: "1em 1em"
                  }}
                >
                  <Box sx={{ display: "flex" }}>
                    <Avatar alt="Remy Sharp" src={ImageAvatar} />
                    <Box ml={2}>
                      <Typography variant="subtitle2" component="p">
                        Evariste Siallou
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        color="textSecondary"
                        component="p"
                      >
                        August 15, 2023
                      </Typography>
                    </Box>
                  </Box>
                  <Box>
                    <BookmarkBorderIcon />
                  </Box>
                </Box>
              </Card>
            </Grid>
          ))}
        </Grid>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            margin: "3em 0",
          }}
        >
          <Pagination count={10} variant="outlined" shape="rounded" />
        </Box>
      </Container>
    </div>
  );
};
export {cards}
export default Blog;
