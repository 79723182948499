import React, { createContext, useContext, useState, useEffect } from 'react';
import { getValueFromLocalStorage } from './Utils';
import MmfApi from './MmfApi';
import jwt_decode from 'jwt-decode';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoadingAuthContext, setIsLoadingAuthContext] = useState(true);
  const [user, setUser] = useState();
  const [error, setError] = useState();
  const [stepSetup, setStepSetup] = useState("");

  const [projects, setProjects] = useState();

  
  useEffect(() => {
    setup();
    return () => {
      setStepSetup("");
    };
  }, []);


  /**
   * Setup
   * @returns 
   */
  const setup = async () => {
    try {
      setStepSetup("user");
      // We load user and save them in context
      const currentUser = await getCurrentUser();
      if (currentUser) {
          await login(currentUser)
      };
      
      // loading all project
      setStepSetup("projects");
      await loadProjects();
      // set loading Auth context to false
      setIsLoadingAuthContext(false);
      
    } catch (e) {
      setError(e.message);
      setIsLoadingAuthContext(false)
    }
  };

  // 
  const loadProjects = async () => {
    // Retireve user token
    let userToken = getValueFromLocalStorage('_MOMAII_AUTH_LOGGED_IN_TOKEN', null)
    if (userToken) {
      const response = await MmfApi.getAllProjects(userToken);
      if (response.status === 200) {
        console.log("PROJ PROJ PROJ PROJ",response.data);
        setProjects(response.data);
        return true;
      } else {
        console.log("error on loading projects");
        return false;
      }
    }
  };

  // get user with valid token token
  const getCurrentUser = async () => {
    // Retireve user token
    let userToken = getValueFromLocalStorage('_MOMAII_AUTH_LOGGED_IN_TOKEN', null)
    if (userToken) {
      const isTokenExpired = await checkIfTokenExpired(userToken)
      if (isTokenExpired) {
        await refreshToken();
        userToken = getValueFromLocalStorage('_MOMAII_AUTH_LOGGED_IN_TOKEN', null);
      };
      // retrieve user
      try {
          const response = await MmfApi.userMe(userToken);
          return response.data;
      } catch (error) {
          return null;
      }
    }
  }


  const checkIfTokenExpired = async (storedToken) => {
    if (storedToken) {
      // Décoder le token JWT pour obtenir les informations, y compris la date d'expiration
      const decodedToken = jwt_decode(storedToken);    
      // Obtenir la date d'expiration du token en secondes
      const expirationTime = decodedToken.exp;
      // Obtenir le temps actuel en secondes
      const currentTime = Math.floor(Date.now() / 1000);
      const expired = currentTime > expirationTime
      return expired
    }
  }

  const refreshToken = async () => {
    try {
      const userRefreshTokenStored = getValueFromLocalStorage('_MOMAII_AUTH_LOGGED_IN_REFRESH_TOKEN', null)
      const response = await MmfApi.refreshToken(userRefreshTokenStored);
      if (response) {
        const data = response.data
        localStorage.setItem('_MOMAII_AUTH_LOGGED_IN_TOKEN', JSON.stringify(data.access_token))
      }
    } catch (error) {
      console.log("ERREUR ERREUR")
    }
  }

  // set user data
  const login = async (userData) => {
    setUser(userData);
  };

  const logout = () => {
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{
        user,
        setUser, 
        isLoadingAuthContext, 
        setIsLoadingAuthContext,
        stepSetup,
        getCurrentUser, 
        login, 
        logout,
        projects
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
